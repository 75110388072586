<template>
    <LayoutEtapas>
        <div class="formgrid grid p-fluid">
            <div class="field col-12 md:col-6 xl:col-3">
                <label>Número Acordo</label>
                <div class="p-inputgroup">
                    <InputText v-model="numeroAcordo" disabled type="text" />
                </div>
            </div>
            <div class="field col-12 md:col-6 xl:col-3">
                <BInput v-model="evento" :desabilita="desabilita" autofocus label="Evento" maxlength="150" name="evento" />
            </div>

            <div class="field col-12 md:col-6 xl:col-3">
                <BInput v-model="tags" :desabilita="desabilita" label="Tags" name="tags" />
            </div>

            <div class="field col-12 md:col-6 xl:col-3">
                <BDropdown
                    v-model="cidadeEstadoEvento"
                    :desabilita="desabilita"
                    :itens="municipios"
                    filter
                    label="Cidade\Estado do Evento"
                    optionLabel="nome"
                    optionValue="nome"
                />
            </div>
        </div>

        <div class="formgrid grid p-fluid">
            <div class="field col-12 md:col-6 lg:col-3 xl:col-3">
                <span v-tooltip="'Data prevista para o <strong>início das ações</strong> pactuadas no acordo'">
                    <BCalendar
                        v-model="dataExecucaoInicial"
                        :desabilita="desabilita"
                        required
                        :temErro="v$.dataExecucaoInicial.$error"
                        label="Data Execução Inicial"
                        :mensagemErro="v$.dataExecucaoInicial.$silentErrors[0]?.$message || ''"
                        @blur="v$.dataExecucaoInicial.$touch()"
                    />
                </span>
            </div>

            <div class="field col-12 md:col-6 lg:col-3 xl:col-3">
                <span v-tooltip="'Data prevista para o <strong>término das ações</strong> pactuadas no acordo'">
                    <BCalendar
                        v-model="dataExecucaoFinal"
                        :desabilita="desabilita"
                        required
                        :temErro="v$.dataExecucaoFinal.$error"
                        label="Data Execução Final"
                        :mensagemErro="v$.dataExecucaoFinal.$silentErrors[0]?.$message || ''"
                        @blur="v$.dataExecucaoFinal.$touch()"
                    />
                </span>
            </div>

            <div class="field col-12 md:col-6 lg:col-3 xl:col-3">
                <span
                    v-tooltip="
                        '<strong>Data</strong> prevista para a <strong>assinatura do acordo</strong>, ou seja, para o <strong>início da vigência</strong>. Todas as informações /ou <strong>alterações</strong> precisam ser inseridas <strong>antes dessa data</strong>. <strong>A partir dessa data</strong> o acordo entrará “Em Execução” e só <strong>poderá ser alterado</strong> por meio de <strong>Aditivo</strong>.'
                    "
                >
                    <BCalendar
                        v-model="dataInicioParceria"
                        :desabilita="desabilita"
                        :mensagemErro="v$.dataInicioParceria.$silentErrors[0]?.$message || ''"
                        :temErro="v$.dataInicioParceria.$error"
                        label="Data Início"
                        required
                        @blur="v$.dataInicioParceria.$touch()"
                    />
                </span>
            </div>
            <div class="field col-12 md:col-6 lg:col-3 xl:col-3">
                <span
                    v-tooltip="
                        '<strong>Fim da vigência</strong> do acordo.<strong> A partir dessa data</strong>, o acordo ficará como “Concluído” e <strong>não será</strong> mais <strong>possível fazer aditivo</strong>. <strong>Sugerimos</strong> que seja, pelo menos, <strong>um mês depois do término das ações</strong> para permitir alterações em caso de imprevistos na execução ou no recebimento da receita.'
                    "
                >
                    <BCalendar
                        v-model="dataFimParceria"
                        :desabilita="desabilita"
                        :mensagemErro="v$.dataFimParceria.$silentErrors[0]?.$message || ''"
                        :temErro="v$.dataFimParceria.$error"
                        label="Data Fim"
                        required
                        @blur="v$.dataFimParceria.$touch()"
                    />
                </span>
            </div>
        </div>

        <div class="formgrid grid p-fluid">
            <div class="field col-12 md:col-6">
                <BDropdown v-model="idParceiro" :desabilita="desabilita" :itens="parceiros" filter required label="Parceiro" @blur="v$.idParceiro.$touch()" />
                <p>
                    <small>
                        <a :class="{ 'valor-verde': this.statusFinanceiro == 0, 'valor-vermelho': this.statusFinanceiro == 1 }">
                            Status Parceiro: {{ this.situacao }}
                        </a>
                    </small>
                </p>
            </div>
        </div>
        <div class="flex">
            <div class="flex-1"></div>
            <Button :disabled="v$.$invalid" @click="onSubmit()">Continuar</Button>
        </div>
    </LayoutEtapas>
</template>

<style>
.valor-verde {
    color: rgb(86, 194, 91);
}

.valor-vermelho {
    color: red;
}
</style>

<script>
import LayoutEtapas from './LayoutEtapas.vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import Services from '../../../service.js';

export default {
    components: {
        LayoutEtapas,
    },
    setup() {
        return {
            v$: useVuelidate({}),
        };
    },
    validations() {
        return {
            dataExecucaoInicial: {
                required: helpers.withMessage('Campo obrigatório', required),
                dataValida: helpers.withMessage('Não é uma data válida', (val) => this.$moment(val, 'DD/MM/YYYY').isValid()),
                dataMenorQueFinal: helpers.withMessage('Não pode ser maior que a data final', (val) => {
                    const dataInicio = this.$moment(val, 'DD/MM/YYYY');
                    const dataFim = this.$moment(this.dataExecucaoFinal, 'DD/MM/YYYY');
                    return dataInicio.isSameOrBefore(dataFim);
                }),
            },
            dataExecucaoFinal: {
                required: helpers.withMessage('Campo obrigatório', required),
                dataValida: helpers.withMessage('Não é uma data válida', (val) => this.$moment(val, 'DD/MM/YYYY').isValid()),
                dataMaiorQueInicial: helpers.withMessage('Não pode ser menor que a data de início', (val) => {
                    const dataFim = this.$moment(val, 'DD/MM/YYYY');
                    const dataInicio = this.$moment(this.dataExecucaoInicial, 'DD/MM/YYYY');
                    return dataFim.isSameOrAfter(dataInicio);
                }),
            },
            dataInicioParceria: {
                required: helpers.withMessage('Campo obrigatório', required),
                dataValida: helpers.withMessage('Não é uma data válida', (val) => this.$moment(val, 'DD/MM/YYYY').isValid()),
                dataMenorQueFinal: helpers.withMessage('Não pode ser maior que a data final', (val) => {
                    const dataInicio = this.$moment(val, 'DD/MM/YYYY');
                    const dataFim = this.$moment(this.dataFimParceria, 'DD/MM/YYYY');
                    return dataInicio.isSameOrBefore(dataFim);
                }),
            },
            dataFimParceria: {
                required: helpers.withMessage('Campo obrigatório', required),
                dataValida: helpers.withMessage('Não é uma data válida', (val) => this.$moment(val, 'DD/MM/YYYY').isValid()),
                dataMaiorQueInicial: helpers.withMessage('Não pode ser menor que a data de início', (val) => {
                    const dataFim = this.$moment(val, 'DD/MM/YYYY');
                    const dataInicio = this.$moment(this.dataInicioParceria, 'DD/MM/YYYY');
                    return dataFim.isSameOrAfter(dataInicio);
                }),
            },

            idParceiro: {
                required: helpers.withMessage('Campo obrigatório', required),
            },
        };
    },
    data() {
        return {
            loading: false,
            desabilita: false,
            parceiros: [],
            municipios: [],
            numeroAcordo: '',
            evento: '',
            idParceiro: null,
            situacao: '',
            statusFinanceiro: 2,
            tags: '',
            cidadeEstadoEvento: '',
            dataExecucaoInicial: '',
            dataExecucaoFinal: '',
            dataInicioParceria: '',
            dataFimParceria: '',
            status: 0,
        };
    },
    methods: {
        onSubmit() {
            this.$store.dispatch('addRequest');
            const dados = {
                id: this.$route.params.id,
                numeroAcordo: this.numeroAcordo,
                evento: this.evento,
                idParceiro: this.idParceiro,
                tags: this.tags,
                cidadeEstadoEvento: this.cidadeEstadoEvento,
                dataExecucaoInicial: this.$moment(this.dataExecucaoInicial, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                dataExecucaoFinal: this.$moment(this.dataExecucaoFinal, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                dataInicioParceria: this.$moment(this.dataInicioParceria, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                dataFimParceria: this.$moment(this.dataFimParceria, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                status: this.status,
            };
            Services.InserirOuAtualizarParceriaDadosGerais(dados)
                .then((response) => {
                    if (response && response.success) {
                        this.$router.push({
                            name: 'MapaParceiros_Parceria_Formulario_Clausulas',
                            params: {
                                id: response.data.id,
                            },
                        });
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
        carregaComboParceiro() {
            this.$store.dispatch('addRequest');
            var filtro = {
                cnpj: '',
                razaoSocial: '',
                situacao: '',
            };
            Services.SelectAllParceiros(filtro)
                .then((response) => {
                    if (response && response.success) {
                        this.parceiros = response.data.map((e) => {
                            return {
                                id: e.id,
                                label: `${e.id} - ${e.razaoSocial}`,
                            };
                        });
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
        carregaComboMunicipios() {
            this.$store.dispatch('addRequest');
            Services.ObterMunicipios()
                .then((response) => {
                    if (response && response.success && response.data.result) {
                        this.municipios = response.data.result;
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
        carregaDados() {
            const id = this.$route.params.id;
            this.$store.dispatch('addRequest');
            Services.SelectByIdParcerias(id)
                .then((response) => {
                    if (response && response.success && response.data) {
                        this.numeroAcordo = response.data.numeroAcordo;
                        this.evento = response.data.evento;
                        this.idParceiro = response.data.idParceiro;
                        this.tags = response.data.tags;
                        this.cidadeEstadoEvento = response.data.cidadeEstadoEvento;
                        this.dataExecucaoInicial = response.data.dataExecussaoInicialFormatada;
                        this.dataExecucaoFinal = response.data.dataExecussaoFinalFormatada;
                        this.dataInicioParceria = response.data.dataInicioParceriaFormatada;
                        this.dataFimParceria = response.data.dataFimParceriaFormatada;
                        this.status = response.data.status;
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
    },
    watch: {
        idParceiro() {
            this.$store.dispatch('addRequest');
            Services.SelectByIdPessoa(this.idParceiro).then((response) => {
                if (response && response.success) {
                    this.parceiro = response.data;
                    this.situacao = this.parceiro.statusFinanceiroDescricao;
                    this.statusFinanceiro = this.parceiro.statusFinanceiro;
                } else {
                    this.parceiro = null;
                }
                this.$store.dispatch('removeRequest');
            });
            this.v$.$touch();
        },
    },

    mounted() {
        if (this.$route.params.id) {
            this.carregaDados();
        }

        this.carregaComboParceiro();
        this.carregaComboMunicipios();
    },
};
</script>
